export class Contact {
  contact_id: number;
  first_name: string;
  last_name: string;
  phone: number;
  email: string;
  address: string;
  custom1: string;
  custom2: string;
  custom3: string;
  description: string;
}

