export class Document {
  document_id: number;
  name: string;
  file_name: any;
  type: string;
  description: string;
  quality: any;
  text: String;
  ocr: String;
}
